<template>
  <section>
    <TogglePanel
      :title="$tc('general')"
      style="margin-top: 10px; padding: 0 10px"
    >
      <div class="row">
        <div class="col-xs-8">
          <div class="form-group form-group-sm">
            <label for="">
              {{ $t("default_interval") }}
              <Tooltip :title="$t('hints.default_interval')" />
            </label>
            <div class="input-group">
              <input
                type="number"
                class="form-control text-center"
                v-model="interval"
                inc="1"
                min="1"
                :max="maxInterval"
                style="z-index: auto"
              />
              <div class="input-group-addon">
                {{ $tc("hour", interval > 1 ? 2 : 1) }}
              </div>
            </div>
          </div>
          <div class="form-group form-group-sm">
            <label
              class="input-checkbox clicable"
              for="startupUpdate"
              @click.stop.prevent="startupUpdate = !startupUpdate"
              style="z-index: 999"
            >
              <i
                id="startupUpdate"
                :class="
                  startupUpdate ? 'fa fa-check-square-o' : 'fa fa-square-o'
                "
              ></i>
              {{ $t("update_at_startup") }}
              <Tooltip :title="$t('hints.update_at_startup')" />
            </label>
          </div>
          <div class="form-group form-group-sm">
            <label
              class="input-checkbox clicable"
              for="iframeVisible"
              @click.stop.prevent="iframeVisible = !iframeVisible"
              style="z-index: 999"
            >
              <i
                id="iframeVisible"
                :class="
                  iframeVisible ? 'fa fa-check-square-o' : 'fa fa-square-o'
                "
              ></i>
              {{ $t("IFrame") }}
              <Tooltip :title="$t('hints.show_in_iframe')" />
            </label>
          </div>
        </div>
      </div>
    </TogglePanel>
    <TogglePanel
      title="toolbar"
      style="margin-top: -10px; padding: 0 10px"
      persistent="toggle_toolbar_items"
    >
      <div class="inner-panel">
        <div v-if="hasTab">
          <label
            class="input-checkbox clicable"
            for="toolbarAlwaysVisible"
            @click.stop.prevent="toolbarAlwaysVisible = !toolbarAlwaysVisible"
          >
            <i
              :class="
                toolbarAlwaysVisible ? 'fa fa-check-square-o' : 'fa fa-square-o'
              "
            ></i>
            {{ $t("always_visible") }}
          </label>
        </div>
        <div v-for="group in toolbarGroupList" :key="group">
          <div
            v-if="group"
            class="toggle-title clicable"
            @click.stop.prevent="toggleToolbarGroup(group)"
          >
            <div>
              {{ $t(`titles.${group}`) }}
            </div>
            <span class="btn btn-xs">
              <i
                :class="
                  isToolbarGroupVisible(group)
                    ? 'fa fa-caret-square-o-up'
                    : 'fa fa-caret-square-o-down'
                "
              ></i>
            </span>
          </div>
          <div
            class="toolbar-group-container"
            v-if="isToolbarGroupVisible(group)"
          >
            <div
              v-for="item in toolbarFilterByGroup(group)"
              :key="item.id"
              :class="{
                'bg-primary': item.id == toolbarDefaultTab
              }"
            >
              <label
                :for="item.id"
                class="input-checkbox clicable"
                @click.stop.prevent="toggleToolbarItem(item.id)"
                :style="{
                  color: !item.enabled ? '#888' : 'inherit'
                }"
              >
                <i
                  :class="
                    item.enabled ? 'fa fa-check-square-o' : 'fa fa-square-o'
                  "
                  style="min-width: 14px; font-size: 90%"
                />
                <span> {{ $t(item.title) }} </span>
                <tooltip v-if="item.hint" :title="$t(item.hint)" />
              </label>
              <!-- <div class="pull-right" v-if="group == 'tabs'"> -->
              <div
                class="clicable tab-selector"
                v-if="group == 'tabs' && item.id != toolbarDefaultTab"
              >
                <span
                  @click.stop.prevent="toolbarDefaultTab = item.id"
                  class="clicable-orange"
                >
                  <i class="fa fa-arrow-left"></i>
                  {{ $t("set_as_default") }}
                </span>
              </div>
              <template v-if="item.id == 'preview' && item.enabled">
                <div class="print-preview-panel" @click="togglePageSettings">
                  <div>
                    <div class="title clicable">
                      {{ $t("page_settings") }}
                    </div>
                    <PageSettingsToolbar
                      class="pdf-tool-bar"
                      v-model="pageSettings"
                      ref="pageSettingsToolbar"
                    />
                  </div>
                </div>
              </template>
              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
    </TogglePanel>
    <TogglePanel
      v-if="hasDataFilter"
      :title="$tc('titles.data_filter', 1)"
      persistent="toggle_toolbar_panel_data_filter"
      :icon="{before: 'fa fa-filter'}"
      style="margin-top: 10px; padding: 0 10px"
    >
      <div>
        <ControlDataSelector
          v-model="dataFilterDataId"
          :connectorId="(dataFilterData && dataFilterData.clp_id) || null"
          :parser="dataListParser"
          :label="$tc('data', 1)"
          :allowedTypes="['bool', 'float', 'int', 'string']"
        />
        <div class="form-group form-group-sm" v-if="dataFilterDataId">
          <div style="margin-bottom: 10px; position: relative; z-index: 101">
            <label
              class="clicable"
              @click.stop.prevent="dataFilterIsRequired = !dataFilterIsRequired"
            >
              <i
                :class="
                  dataFilterIsRequired
                    ? 'fa fa-check-square-o'
                    : 'fa fa-square-o'
                "
              ></i>
              {{ $tc("required", 1) }}
              <Tooltip :title="$t('hints.filter_required')" />
            </label>
          </div>
          <div>
            <span> {{ $tc("label", 1) }} / {{ $tc("value", 1) }} </span>
          </div>
          <div style="clear: both">
            <input
              type="text"
              class="form-control"
              v-model="dataFilterLabel"
              style="width: 60%; display: inline-block"
              :placeholder="$t('label')"
            />
            <input
              type="text"
              class="form-control"
              v-model="dataFilterValue"
              style="width: 40%; display: inline-block"
              :placeholder="$t('default_value')"
            />
          </div>
        </div>
      </div>
    </TogglePanel>
  </section>
</template>

<script>
import {merge} from "lodash";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import Panels from "@/assets/dashboard/panels.json";
import Tooltip from "@/components/tooltip.vue";
import PageSettingsToolbar from "@/components/widgets/page-settings-toolbar.vue";
import ControlDataSelector from "@/components/synoptic/property-editor/controls/control-data-selector.vue";
const defaultPanelOptions = () =>
  Panels.find((panel) => panel.template.template == "EquipmentToolBarPanel")
    ?.template?.options || {};

const defaultDataFilter = () => ({data_id: "", value: "", label: ""});

export {defaultPanelOptions};
export default {
  name: "DetailFormToolbar",
  components: {
    TogglePanel,
    Tooltip,
    PageSettingsToolbar,
    ControlDataSelector
  },
  data() {
    return {
      edgesOptions: [
        {
          value: "leave_them_empty",
          label: "leave_them_empty",
          hint: "hints.edges_options.leave_them_empty",
          enabled: true
        },
        {
          value: "fit_in_time_window",
          label: "fit_in_time_window",
          hint: "hints.edges_options.fit_in_time_window",
          enabled: true
        }
      ],
      cellInterpolation: [
        {
          value: "leave_them_empty",
          label: "leave_them_empty",
          hint: "hints.cell_interpolation.leave_them_empty",
          enabled: true
        },
        {
          value: "last_value",
          label: "last_value",
          hint: "hints.cell_interpolation.last_value",
          enabled: true
        },
        {
          value: "linear_interpolation",
          label: "linear_interpolation",
          hint: "hints.cell_interpolation.linear_interpolation",
          enabled: true
        }
      ],
      visibleToolbarGroups: {}
    };
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    panel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    },
    panelOptions: {
      set(value) {
        this.panel.options = value;
      },
      get() {
        return this?.panel?.options || null;
      }
    },
    screenId() {
      return (this.$store.getters["dashboard/draft"] || {screenId: ""})
        .screenId;
    },
    interval: {
      set(value) {
        let vlr = Math.abs(parseInt(value));
        var history = this.extendPanelOption("history");
        history.interval =
          -1 * (vlr < 1 ? 1 : vlr > this.maxInterval ? this.maxInterval : vlr);
        this.$set(this.panelOptions, "history", history);
      },
      get() {
        var history = this.extendPanelOption("history");
        return -1 * history.interval;
      }
    },
    startupUpdate: {
      set(value) {
        var history = this.extendPanelOption("history");
        history.startupUpdate = value;
        this.$set(this.panelOptions, "history", history);
      },
      get() {
        var history = this.extendPanelOption("history");
        return history.startupUpdate;
      }
    },
    iframeVisible: {
      set(value) {
        var history = this.extendPanelOption("history");
        history.iframeVisible = value;
        this.$set(this.panelOptions, "history", history);
      },
      get() {
        var history = this.extendPanelOption("history");
        return history.iframeVisible ?? false;
      }
    },
    maxInterval() {
      // return (this?.contract?.system_history_short_max_days || 7) * 24;
      return 24;
    },
    toolbarAlwaysVisible: {
      set(value) {
        var toolbar = this.extendPanelOption("toolbar");
        toolbar.alwaysVisible = value;
        this.$set(this.panelOptions, "toolbar", toolbar);
      },
      get() {
        var toolbar = this.extendPanelOption("toolbar");
        return toolbar.alwaysVisible;
      }
    },
    toolbarItems: {
      set(value) {
        var toolbar = this.extendPanelOption("toolbar");
        toolbar.items = value;
        this.$set(this.panelOptions, "toolbar", toolbar);
      },
      get() {
        var toolbar = this.extendPanelOption("toolbar");
        return toolbar.items || {};
        // return {
        //   ...defaultPanelOptions().toolbar.items,
        //   ...JSON.parse(
        //     JSON.stringify(this?.panelOptions?.toolbar?.items || {})
        //   )
        // };
      }
    },
    toolbarGroupList() {
      let groups = {};
      this.toolbarList.forEach(({group}) => {
        if (!(group in groups)) groups[group] = true;
      });
      return Object.keys(groups).sort();
    },
    toolbarList() {
      let source = defaultPanelOptions().toolbar.items;
      return Object.keys(source)
        .map((id) => ({
          id: id,
          ...source[id],
          ...{
            enabled: this.toolbarItems[id].enabled
          }
        }))
        .filter(({editable}) => editable);
    },
    hasTab() {
      return this.toolbarGroupList.indexOf("tabs") >= 0;
    },
    // tabs: future use
    toolbarDefaultTab: {
      set(value) {
        var toolbar = {
          ...defaultPanelOptions().toolbar,
          ...JSON.parse(JSON.stringify(this?.panelOptions?.toolbar || {}))
        };
        toolbar.defaultTab = value;
        this.$set(this.panelOptions, "toolbar", toolbar);
      },
      get() {
        var toolbar = {
          ...defaultPanelOptions().toolbar,
          ...JSON.parse(JSON.stringify(this?.panelOptions?.toolbar || {}))
        };
        return "defaultTab" in toolbar ? toolbar.defaultTab : "chartTab";
      }
    },
    // data list:future use
    dataList: {
      set(values) {
        this.$set(this.panel.options, "dataList", values);
      },
      get() {
        return this?.panel?.options?.dataList || [];
      }
    },
    previewEnabled() {
      return this?.panelOptions?.toolbar?.items?.preview?.enabled ?? false;
    },
    pageSettings: {
      set(value) {
        this.$set(
          this.panelOptions,
          "pageSettings",
          JSON.parse(JSON.stringify(value))
        );
      },
      get() {
        return this.extendPanelOption("pageSettings");
      }
    },
    hasDataFilter() {
      return this?.toolbarItems?.dataFilter?.enabled;
    },
    dataFilterDataId: {
      set(value) {
        let dataFilter = {
          ...defaultDataFilter(),
          ...this?.panelOptions?.dataFilter
        };
        if (dataFilter.data_id && !value) {
          dataFilter.value = "";
          dataFilter.label = "";
        }
        dataFilter.data_id = value;
        this.$set(this.panel.options, "dataFilter", dataFilter);
      },
      get() {
        return this?.panelOptions?.dataFilter?.data_id ?? "";
      }
    },
    dataFilterLabel: {
      set(value) {
        let dataFilter = {
          ...defaultDataFilter(),
          ...this?.panel?.options?.dataFilter
        };
        dataFilter.label = value;
        this.$set(this.panel.options, "dataFilter", dataFilter);
      },
      get() {
        return this?.panel?.options?.dataFilter?.label ?? "";
      }
    },
    dataFilterValue: {
      set(value) {
        let dataFilter = {
          ...defaultDataFilter(),
          ...this?.panel?.options?.dataFilter
        };
        dataFilter.value = value;
        this.$set(this.panel.options, "dataFilter", dataFilter);
      },
      get() {
        return this?.panel?.options?.dataFilter?.value ?? "";
      }
    },
    dataFilterIsRequired: {
      set(value) {
        let dataFilter = {
          ...defaultDataFilter(),
          ...this?.panel?.options?.dataFilter
        };
        dataFilter.isRequired = value;
        this.$set(this.panel.options, "dataFilter", dataFilter);
      },
      get() {
        return this?.panel?.options?.dataFilter?.isRequired ?? false;
      }
    },
    dataFilterData() {
      return (
        this.dataFilterDataId &&
        this.$store.getters["dashboard/dataList"].find(
          ({id}) => parseInt(id) == parseInt(this.dataFilterDataId)
        )
      );
    }
  },
  watch: {
    panelOptions: {
      handler(n) {
        if (n) {
          this.$store.dispatch("dashboard/saveDraftPanel", {
            panel: this.panel,
            screenId: this.screenId
          });
        }
      },
      deep: true
    },
    hasDataFilter(n, o) {
      if (o && !n) {
        this.dataFilterDataId = "";
        this.dataFilterValue = "";
      }
    }
  },
  methods: {
    extendPanelOption(attr) {
      // Merge new properties with persisted ones.
      // It keeps backward compatibility of the desired panel option object
      var o = JSON.parse(JSON.stringify((defaultPanelOptions() || {})[attr]));
      var n = JSON.parse(
        JSON.stringify((this?.panelOptions || {})[attr] || {})
      );
      return merge(o, n);
    },
    toolbarFilterByGroup(query) {
      return this.toolbarList.filter(({group}) => group == query);
    },
    dataListParser(lst) {
      return (lst || []).filter(
        ({id, history_enabled}) => isNaN(Number(id)) || history_enabled
      );
    },
    toggleToolbarItem(id) {
      let toolbarItems = JSON.parse(JSON.stringify(this.toolbarItems));
      toolbarItems[id].enabled = !toolbarItems[id].enabled;
      if (
        !toolbarItems.calendarInput.enabled &&
        !toolbarItems.calendarButton.enabled
      ) {
        toolbarItems.calendarIntervalRange.enabled = false;
      }
      this.toolbarItems = toolbarItems;
    },
    toggleToolbarGroup(group) {
      this.$set(
        this.visibleToolbarGroups,
        group,
        !(this?.visibleToolbarGroups || {})[group]
      );
    },
    isToolbarGroupVisible(group) {
      return (this?.visibleToolbarGroups || {})[group] ? true : false;
    },
    togglePageSettings($event) {
      if (this.$refs.pageSettingsToolbar.length) {
        this.$refs.pageSettingsToolbar[0].toggle($event);
      }
    }
  },
  created() {},
  beforeDestroy() {}
};
</script>

<style scoped>
section > .content {
  min-height: auto;
}

div.option {
  margin: 3px;
  white-space: nowrap;
}

div.option > i {
  margin-right: 3px;
}

.import-options {
  padding: 0;
  margin: 0;
  text-align: center;
  white-space: nowrap;
}

.import-options > span.btn {
  margin: 0 5px 0 0;
}

div.data-list {
  margin-top: -10px;
}

div.item {
  position: relative;
  width: 100%;
  font-size: 1em;
  border-top: 1px solid #e4e4e4;
  z-index: 1;
}

div.item-title {
  padding: 4px 22px 4px 2px;
  font-weight: 600;
  white-space: normal;
  width: 100%;
}

div.item-toolbar {
  position: absolute;
  top: 4px;
  right: 5px;
  z-index: 4;
}

div.item-toolbar > i {
  padding: 0 4px;
  min-width: 22px;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

div.toggle-title {
  position: relative;
  margin-top: 6px;
  padding: 2px 0;
}

div.toggle-title > span.btn {
  position: absolute;
  right: 0;
  top: 0;
  padding: 2px;
}

div.toolbar-group-container {
  margin: 5px 0 0 0px;
}

div.toolbar-group-container label {
  margin: 3px 0;
  padding-left: 4px;
}

div.toolbar-group-container > div {
  position: relative;
}

div.toolbar-group-container > div > div.tab-selector {
  position: absolute;
  right: 0;
  top: 2px;
  width: 120px;
  font-size: 9pt;
  color: transparent;
}

div.toolbar-group-container > div:hover > div.tab-selector {
  cursor: pointer;
  color: #2a6599;
}

label {
  white-space: nowrap;
}

.inner-panel {
  padding: 0 10px;
}

.print-preview-panel {
  position: relative;
  margin: 0 -10px 15px 0;
  background: white;
}

.print-preview-panel:hover {
  cursor: pointer;
}

.print-preview-panel > div {
  width: 100%;
  height: 42px;
  border-radius: 5px;
  background: white;
  border: 1px solid #eee;
}
.print-preview-panel > div > div.title {
  margin: 10px;
  padding: 0;
  color: #666;
  font-weight: 400;
}
.print-preview-panel > div > .pdf-tool-bar {
  position: absolute;
  top: -2px;
  right: 0;
}
</style>
