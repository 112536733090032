<template>
  <DetailForm persistent="toggle_custom_panel">
    <DetailFormExtendedPropertiesPanel />
  </DetailForm>
</template>

<script>
import DetailForm from "@/components/control-sidebar/property-editors/detail-form.vue";
import DetailFormExtendedPropertiesPanel from "@/components/control-sidebar/property-editors/detail-form-extended-properties-panel.vue";
export default {
  name: "ExtendedPropertiesPanelForm",
  components: {
    DetailForm,
    DetailFormExtendedPropertiesPanel
  }
};
</script>
