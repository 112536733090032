<template>
  <section
    class="screen-panel-editor"
    v-if="isEnabled"
    :style="{width: width, padding: '0 16px 0 0'}"
  >
    <portal to="context-navbar">
      <DashboardEditorToolbar
        v-if="screens && screens.length"
        :screen="screen"
      />
    </portal>
    <KeyboardHandler ref="kb">
      <DashboardEquipment
        mode="editor"
        v-if="!updating"
        :equipmentId="connectorId"
        :panelName="currentDraftPanelName"
        :screenId="screenId"
        :style="{
          transform: `scale(${zoomScale})`
        }"
        :key="scriptsSync"
      />
    </KeyboardHandler>
    <ControlPageZoom />
  </section>
  <Alert v-else-if="screen" type="warning" style="margin-top: 2rem">
    <p class="text-black">
      {{ $tc("titles.you_dont_have_permission", 1) }}
    </p>
    <router-link to="/dashboard/screen/edit/layout" class="alert-link">
      {{ $tc("back", 2) }}
    </router-link>
  </Alert>
</template>

<script>
import {isEqual, debounce} from "lodash";
import DashboardEquipment from "./DashboardEquipment.vue";
import KeyboardHandler from "@/components/editor/keyboard-handler.vue";
import Alert from "@/components/widgets/alert";
import Panels from "@/assets/dashboard/panels.json";
import Layouts from "@/assets/dashboard/layouts.json";

import DashboardEditorToolbar from "@/components/editor/dashboard-editor-toolbar.vue";
import ControlPageZoom from "@/components/editor/control-page-zoom.vue";

// import DashboardForm from "@/components/control-sidebar/property-editors/dashboard-form.vue";
import MainForm from "@/components/control-sidebar/property-editors/main-form.vue";
import TableForm from "@/components/control-sidebar/property-editors/table-form.vue";
import ImageForm from "@/components/control-sidebar/property-editors/image-form.vue";
import RichTextForm from "@/components/control-sidebar/property-editors/rich-text-form.vue";
import DeviceListTableForm from "@/components/control-sidebar/property-editors/device-list-table-form.vue";
import AlarmForm from "@/components/control-sidebar/property-editors/alarm-form.vue";
import EquipmentToolbarForm from "@/components/control-sidebar/property-editors/equipment-toolbar-form.vue";
import EquipmentCardPanelForm from "@/components/control-sidebar/property-editors/equipment-card-panel-form.vue";
import ConnectorDataListForm from "@/components/control-sidebar/property-editors/connector-data-list-form.vue";
import ChartForm from "@/components/control-sidebar/property-editors/chart-form.vue";
import HistoryForm from "@/components/control-sidebar/property-editors/history-form.vue";
import SynopticPropertyEditor from "@/components/synoptic/property-editor";
import EquipmentSearchPanelForm from "@/components/control-sidebar/property-editors/equipment-search-panel-form.vue";
import CustomPanelForm from "@/components/control-sidebar/property-editors/custom-panel-form.vue";
import ExtendedPropertiesPanelForm from "@/components/control-sidebar/property-editors/extended-properties-panel-form.vue";

// Specific panel widgets
import Canvas from "@/components/synoptic/property-editor/controls/canvas";
import DetailFormAlarm from "@/components/control-sidebar/property-editors/detail-form-alarm.vue";
import DetailFormToolbar from "@/components/control-sidebar/property-editors/detail-form-toolbar.vue";
import DetailFormHistory from "@/components/control-sidebar/property-editors/detail-form-history.vue";
import DetailFormData from "@/components/control-sidebar/property-editors/detail-form-data.vue";
import DetailFormRichText from "@/components/control-sidebar/property-editors/detail-form-rich-text.vue";
import DetailFormChart from "@/components/control-sidebar/property-editors/detail-form-chart.vue";
import DetailFormImage from "@/components/control-sidebar/property-editors/detail-form-image.vue";
import DetailFormConnectorCards from "@/components/control-sidebar/property-editors/detail-form-connector-cards.vue";
import DetailFormDeviceList from "@/components/control-sidebar/property-editors/detail-form-device-list.vue";
import DetailFormEquipmentSearch from "@/components/control-sidebar/property-editors/detail-form-equipment-search.vue";
import DetailFormTable from "@/components/control-sidebar/property-editors/detail-form-table.vue";
import DetailFormCustomPanel from "@/components/control-sidebar/property-editors/detail-form-custom-panel.vue";
import DetailFormExtendedPropertiesPanel from "@/components/control-sidebar/property-editors/detail-form-extended-properties-panel.vue";
import {
  BaseLayoutConfig,
  panelMerge,
  panelPosition
} from "@/services/dashboard.js";

// Panel component Map x Property panel editor
const panelPropertyForm = {
  DashboardImagePanel: {form: ImageForm, widget: DetailFormImage},
  DashboardRichTextPanel: {form: RichTextForm, widget: DetailFormRichText},
  DeviceListTablePanel: {
    form: DeviceListTableForm,
    widget: DetailFormDeviceList
  },
  EquipmentAlarmPanel: {form: AlarmForm, widget: DetailFormAlarm},
  EquipmentCardPanel: {
    form: EquipmentCardPanelForm,
    widget: DetailFormConnectorCards
  },
  EquipmentDataPanel: {
    form: ConnectorDataListForm,
    widget: DetailFormData
  },
  EquipmentHistoryChartPanel: {form: ChartForm, widget: DetailFormChart},
  EquipmentHistoryPanel: {form: HistoryForm, widget: DetailFormHistory},
  SynopticPanel: {form: SynopticPropertyEditor, widget: Canvas},
  DashboardTablePanel: {form: TableForm, widget: DetailFormTable},
  EquipmentSearchPanel: {
    form: EquipmentSearchPanelForm,
    widget: DetailFormEquipmentSearch
  },
  EquipmentToolBarPanel: {
    form: EquipmentToolbarForm,
    widget: DetailFormToolbar
  },
  EquipmentCustomPanel: {
    form: CustomPanelForm,
    widget: DetailFormCustomPanel
  },
  EquipmentExtendedPropertiesPanel: {
    form: ExtendedPropertiesPanelForm,
    widget: DetailFormExtendedPropertiesPanel
  }
};

const resizeCols = (row) => {
  let len = row.length;
  let width = Math.round((100 / len) * 100) / 100;
  row.forEach((col) => {
    col.width = width;
  });
};

const PANEL = (query) => {
  let panel = null;
  if (query?.name) {
    panel = Panels.find((item) => item.name == query.name);
  } else if (query?.template) {
    panel = Panels.find((item) => item.template.template == query.template);
  }
  return panel || null;
};

export default {
  name: "DashboardScreenEditor",
  components: {
    DashboardEquipment,
    KeyboardHandler,
    Alert,
    DashboardEditorToolbar,
    ControlPageZoom
  },
  props: {
    screenId: {
      type: [String, Number],
      required: false,
      default: () => this?.$store?.getters["dashboard/getRecentScreenId"]
    },
    panelName: {
      type: String,
      required: false,
      default: () => ""
    }
  },
  data() {
    return {
      controlsIgnoredUpdates: {},
      width: "100%",
      updating: false
    };
  },
  computed: {
    contractId() {
      return this.$store.getters["user/loggedUser"]?.contract_id || "";
    },
    isEnabled() {
      if (this?.screen) {
        if (!this?.screen?.public) {
          return true;
        }
      }
      return false;
    },
    screen() {
      // return this.$store.getters["dashboard/screen"](this.screenId);
      return this.screens.find(
        ({id}) => parseInt(id) == parseInt(this.screenId)
      );
    },
    screens() {
      return this.$store.getters["dashboard/activeScreens"] || [];
    },
    refMap() {
      return (
        this.$store.getters["dashboard/screenRefMap"](this.screenId) || null
      );
    },
    connectorId() {
      if (this.refMap && this.refMap.conn1) {
        return this.refMap.conn1;
      }
      let lst = this?.screen?.reference_connectors || [];
      if (lst.length) {
        return lst[0].id;
      }
      return undefined;
    },
    draft() {
      let draft = this.$store.getters["dashboard/draft"] || null;
      return draft && draft?.screenId == this.screenId ? draft : null;
    },
    template() {
      return this?.draft ? this.draft.template : null;
    },
    sidebar() {
      return this.$store.getters["dashboard/sidebar"] || null;
    },
    sidebarCollapsed() {
      return this.sidebar && this.sidebar.collapsed;
    },
    currentDraftPanel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    },
    currentDraftPanelName() {
      return this?.currentDraftPanel?.name || "";
    },
    zoomScale() {
      return this.$root.editorZoom;
    },
    defaultPanel() {
      return this.$store.getters["dashboard/defaultPanelConfiguration"];
    },
    connectorDataList() {
      return this.connectorId
        ? (this.$store.getters["dashboard/dataList"] || []).filter(
            ({clp_id}) => parseInt(clp_id) == parseInt(this.connectorId)
          )
        : [];
    },
    scriptsSync() {
      return this.$store.getters["scripts/sync"] || 0;
    }
  },
  watch: {
    screenId(n, o) {
      if (n == "new" && o) {
        if (!this.screen) {
          this.fetchScreens().then(() => {
            this.$root.$emit("dashboard:editor", {action: "newDashboard"});
          });
          return;
        }
        this.$root.$emit("dashboard:editor", {action: "newDashboard"});
      }
    },
    screen: {
      handler(n) {
        if (n) {
          if (!this.template) {
            this.$store.dispatch("dashboard/initDraft", this.screenId);
          }
        }
      },
      deep: true
    },
    draft: {
      handler(n, o) {
        if (!o && n) {
          this.$nextTick(() => {
            this.initPropertyForm();
            this.$nextTick(() => {
              setTimeout(
                () => {
                  this.updating = false;
                  this.$root.$emit("dashboard:editor", {
                    action: "updateWidth"
                  });
                },
                10,
                this
              );
            });
          });
        }
      }
    },
    connectorId: {
      handler(n, o) {
        if (n) {
          if (o && parseInt(n) != parseInt(o)) {
            this.resetDisplay();
          }
          if (!this.connectorDataList.length) {
            this.fetchConnectorDataList();
          }
        }
        this.$emit("equipmentChanged", n);
      },
      immediate: true
    },
    sidebar: {
      handler(n, o) {
        if (n && !o) {
          this.sizeBarResizeMonitor();
        }
      },
      deep: true
    },
    sidebarCollapsed() {
      this.updateWidth();
    },
    zoomScale() {
      this.updateWidth();
    },
    $route(n, o) {
      if (
        n &&
        o &&
        this.currentDraftPanelName &&
        o.path.indexOf(this.currentDraftPanelName) >= 0 &&
        o.path.split("/").length > n.path.split("/").length
      ) {
        this.switchToDashboardEditor();
      }
    }
  },
  methods: {
    setDrawerTab(name) {
      this.$nextTick(() => {
        this.$nextTick(() => {
          setTimeout(
            () => {
              this.$root.$emit("drawer:event", {
                action: "tab:activate",
                details: name || "components"
              });
            },
            100,
            this
          );
        });
      });
    },
    fetchScreens() {
      return this.$store.dispatch("dashboard/fetchScreens", {
        contract_id: this.contractId
      });
    },
    panelFocus() {
      if (this?.$refs?.kb || null) {
        let x = window.scrollX,
          y = window.scrollY;
        this.$refs.kb.$el.focus();
        window.scrollTo(x, y);
      }
    },
    resetDisplay() {
      if (this.connectorId) {
        this.$store.commit("RESET_DISPLAY", {equipmentId: this.connectorId});
      }
    },
    addPanel(template, row, col, name) {
      let layout = template.layout || [];
      if (col == -1) {
        layout[row].push({panels: [], width: 0});
        resizeCols(layout[row]);
        col = layout[row].length - 1;
      }
      let panelName = name;
      let panel = JSON.parse(JSON.stringify(PANEL({name: panelName})));
      if (!panel.singleton) {
        panelName = this.$utils.uuid();
      }
      if (col > 0 && layout[row][col].panels.length > 1) {
        panel.template.style["min-height"] = ""; // auto
      }
      panel.template.name = panelName;
      layout[row][col].panels.push(panelName);

      // assign dynamic name
      template.panels.push(panel.template);
      return template;
    },
    // test: app.__vue__.$root.$emit("dashboard:editor",{action:"previewDashboard"})
    previewDashboard() {
      this.active = "";
      this.modal = "";
      this.$store.commit("dashboard/SET_TEMPLATE", {
        id: this.screen.id,
        data: this.template
      });
      if (this.template?.draft?.refMap?.conn1) {
        this.goto(
          `/dashboard/equipment/${this.template?.draft?.refMap?.conn1}/${this.screen.id}?tpl=draft`
        );
      } else {
        this.goto(`/dashboard/equipment/screen/${this.screen.id}?tpl=draft`);
      }
    },
    // test: app.__vue__.$root.$emit("dashboard:editor",{action:"newDashboard"})
    newDashboard() {
      this.$store.dispatch("dashboard/create").then((draft) => {
        if (draft && draft.screenId != this.screenId) {
          this.$utils.notifyUser(
            this,
            this.$t(`dashboard created`) + ` (${draft.screenId})`,
            {
              clear: true
            }
          );
          this.cleanUp();
          this.$store.commit("dashboard/RESET_DRAFT");
          this.$store
            .dispatch("dashboard/initDraft", draft.screenId)
            .then((d) => {
              if (this?.$route?.query?.connector_id) {
                this.$store.dispatch("dashboard/updateRefMap", {
                  screenId: draft.screenId,
                  ref_map: {
                    conn1: parseInt(this?.$route?.query?.connector_id)
                  }
                });
              }
              if (this?.$route?.query?.parent) {
                let nodeId =
                  this?.$route?.query?.parent == "trash_can"
                    ? "root"
                    : this?.$route?.query?.parent;
                this.$store.dispatch("dashboard/setParent", {
                  dbKey: "tree_screen",
                  id: draft.screenId,
                  parentId: nodeId
                });
              }
              this.goto(`/dashboard/screen/edit/${draft.screenId}`);
              this.$nextTick(() => {
                let layout = Layouts.find((i) => i.default) || Layouts[0];
                this.$root.$emit("dashboard:editor", {
                  action: "addLayout",
                  data: {
                    row: -1,
                    layout: JSON.parse(JSON.stringify(layout.template))
                  }
                });
              });
            });
        }
      });
    },
    // test: app.__vue__.$root.$emit("dashboard:open",{action:"openDashboard", data:{screenId:2013}})
    openDashboard(config) {
      if (!config || !config.screenId || config.screenId == this.screenId)
        return;
      this.updating = true;
      this.cleanUp();
      this.$nextTick(() => {
        this.$store
          .dispatch("dashboard/initDraft", config.screenId)
          .then(() => {
            this.editDashboard();
          });
      });
    },
    // test: app.__vue__.$root.$emit("dashboard:editor",{action:"editDashboard"})
    editDashboard() {
      // DashboardForm
      this.switchToDashboardEditor();
      let draft = this.$store.getters["dashboard/draft"];
      if (draft) {
        this.goto(`/dashboard/screen/edit/${draft.screenId}`);
      }
    },
    switchToDashboardEditor() {
      this.$store.dispatch("synoptic/resetState");
      this.$store.dispatch("dashboard/resetCurrentDraftPanel");
      this.$root.$emit("controlSidebar:setContent", MainForm);
    },
    cleaupLinkedPanels(template) {
      template.linkedPanels = template.linkedPanels || {};
      Object.keys(template.linkedPanels).forEach((k) => {
        if (template.panels.findIndex(({name}) => name == k) == -1) {
          delete template.linkedPanels[k];
        }
      });
      this.$store.commit("synoptic/RESET_PANEL");
    },
    // test: app.__vue__.$root.$emit("dashboard:editor",{action:"setPanel", data:{row:0, col:1, pos: 1, panel: panel} })
    setPanelAt(template, config) {
      // console.log("setPanelAt");
      let layout = template.layout || [];
      let panels = template.panels || [];
      template.linkedPanels = template.linkedPanels || {};
      // TODO: validates existing coordinate
      const row = (config || {}).row;
      const col = (config || {}).col;
      const pos = (config || {}).pos;
      const linkedScreen = (config || {}).linkedScreen;
      let panel = (config || {}).panel; // panel is already the final (panel.template)
      if (panel) {
        let ix = panels.findIndex(
          ({name}) => name == layout[row][col].panels[pos]
        );
        if (ix >= 0) {
          let isOriginalRow =
            layout[row].filter((c) => {
              if (c?.panels?.length != 1) return false; // only applicable for non pilled panels
              return panels.find(
                ({name, template}) =>
                  name == c?.panels[0] && template == this.defaultPanel.template
              );
            }).length == layout[row].length;
          let prvPanel = panels.splice(ix, 1)[0];
          if (col > 0) {
            panel.style["min-height"] = "";
            panel.style["max-height"] = "";
            panel.style["heightProportion"] = "";
          } else {
            if (prvPanel.style && (col != 0 || !isOriginalRow)) {
              for (var p in prvPanel.style) {
                if (
                  [
                    "min-height",
                    "max-height",
                    "overflow-v",
                    "overflow-h"
                  ].indexOf(p) >= 0
                ) {
                  panel.style[p] = prvPanel.style[p];
                }
              }
            }
          }
        }
        panels.push(panel);
        layout[row][col].panels[pos] = panel.name;
        // if it is just a single panel, get into the content properties editor straight way
        if (linkedScreen) {
          template.linkedPanels[panel.name] = linkedScreen;
          template.panels[template.panels.length - 1] = panelMerge(
            structuredClone(panel),
            panel,
            linkedScreen
          );
        }
        if (
          template.panels.length == 1 &&
          panel.template != this.defaultPanel.template
        ) {
          this.$nextTick(() => {
            this.$nextTick(() => {
              this.$root.$emit("dashboard:editor", {
                action: "editPanel",
                data: {
                  panelName: panel.name,
                  showContentProperties: true
                }
              });
            });
          });
        }
      }
      this.cleaupLinkedPanels(template);
      if (panel.template == this.defaultPanel.template) {
        this.setDrawerTab("components");
      }
      return template;
    },
    // test: app.__vue__.$root.$emit("dashboard:editor",{action:"setPanelSync", data:{panelName:"0e89229d-1980-4277-939d-bb7899bc6e65", option: true} })
    setPanelSync(template, config) {
      let panelName = (config || {}).panelName;
      if (!panelName) return template;
      template.linkedPanels = template.linkedPanels || {};
      if (panelName in template.linkedPanels) {
        let linkedPanel = template.linkedPanels[panelName];
        linkedPanel.syncEnabled = config.option || false;
        if (linkedPanel.syncEnabled) {
          let linkedTemplate = window.localStorage.getItem(
            `dashboard(${linkedPanel.screenId})`
          );
          if (linkedTemplate) {
            linkedTemplate = JSON.parse(linkedTemplate);
          } else {
            linkedTemplate = this.$store.getters["dashboard/template"](
              linkedPanel.screenId
            );
          }
          if (linkedTemplate) {
            let panel = linkedTemplate.panels.find(
              ({name}) => name == panelName
            );
            if (panel) {
              this.$nextTick(() => {
                setTimeout(() => {
                  this.editDashboard();
                }, 200);
              });
              return this.setPanelAt(template, {
                ...panelPosition(template, panelName),
                panel: panel,
                linkedScreen: linkedPanel
              });
            }
          }
        }
      }
      return template;
    },
    getColumnHeight(panels) {
      let height = panels.lenght - 1 * 10; // gap beetween panels=10
      var value;
      panels.forEach((panel) => {
        value = ((panel?.style || {})["min-height"] || "auto").replace(
          /(\D+)/g,
          ""
        );
        height += isNaN(Number(value)) ? 0 : parseInt(value);
      });
      return height;
    },
    // test: app.__vue__.$root.$emit("dashboard:editor",{action:"addLayout", data:{row:0, layout: ... } })
    addLayout(template, config) {
      let insertRow = (config || {}).row;
      if (insertRow == undefined) insertRow = -1;
      let panelBottomMargin = (config || {})?.panelBottomMargin || 10;
      let r, c;
      let layout = template.layout || [];

      (config.layout || []).forEach((row) => {
        if (insertRow == -1) {
          layout.push([]);
          r = layout.length - 1;
        } else {
          r = r === undefined ? insertRow : r + 1;
          layout.splice(r, 0, []);
        }
        (row || []).forEach((col) => {
          c = -1;
          (col.panels || []).forEach((panelName) => {
            panelName = panelName || this.defaultPanel.name;
            this.addPanel(template, r, c, panelName);
            c = layout[r].length - 1;
            // calculate first column panel height
            /*
            if (c == 0 && row.length > 1) {
              let style = template.panels[template.panels.length - 1].style;
              if (row.length > 1) {
                let h = parseInt(style["min-height"].replace(/px/, ""));
                let n = (row[c + 1]?.panels || []).length;
                let minHeight = h * n + panelBottomMargin * (n - 1);
                style["min-height"] = `${minHeight}px`;
              }
            }
            */
            if (row.length > 1 && row[c]?.panels?.length == 1) {
              let style = template.panels[template.panels.length - 1].style;
              let h = parseInt(style["min-height"].replace(/px/, ""));
              // currently layouts accept two
              var nPanels = (row[c ? 0 : 1]?.panels || []).length;
              let minHeight = h * nPanels + panelBottomMargin * (nPanels - 1);
              style["min-height"] = `${minHeight}px`;
            }
          });
        });
      });
      this.resetLayoutConfig(template);
      return template;
    },
    // test: app.__vue__.$root.$emit("dashboard:editor",{action:"addRow", data:{row:0, nCols:3, panelName:"blank_panel"} })
    addRow(template, config) {
      let row = (config || {}).row;
      let nCols = (config || {}).nCols;
      let panelName = (config || {}).panelName;
      // let panelTplName =
      //   PANEL({ name: (config || {}).panelName })?.template ||
      //   "EquipmentEmptyPanel";
      if (row == undefined) row = -1;
      let layout = template.layout || [];
      if (row == -1) {
        layout.push([]);
        this.addPanel(template, layout.length - 1, -1, panelName);
        row = layout.lenght - 1;
      } else {
        if (row < layout.length) {
          layout.splice(row, 0, []);
          this.addPanel(template, row, -1, panelName);
        }
      }
      if (nCols && nCols > 1) {
        for (var c = 1; c < nCols; c++) {
          this.addPanel(template, row, -1, panelName);
        }
      }
      this.resetLayoutConfig(template);
      return template;
    },
    // test: app.__vue__.$root.$emit("dashboard:editor",{action:"delRow", data:{row:0} })
    delRow(template, config) {
      let layout = template.layout || [];
      let ir = (config || {}).row;
      if (ir < 0) ir = layout.length - 1;
      if (ir >= 0 && ir < layout.length) {
        layout.splice(ir, 1)[0].forEach((col) => {
          (col?.panels || []).forEach((panelName) => {
            var pos = template.panels.findIndex(({name}) => name == panelName);
            if (pos > -1) {
              template.panels.splice(pos, 1);
            }
          });
        });
        if (!layout.length) {
          this.addLayout(template, {
            row: -1,
            layout: JSON.parse(
              JSON.stringify(Layouts.find((item) => item.default).template)
            )
          });
        }
      }
      this.resetLayoutConfig(template);
      this.cleaupLinkedPanels(template);
      this.setDrawerTab("components");
      return template;
    },
    // test: app.__vue__.$root.$emit("dashboard:editor",{action:"addCol", data:{row:0} })
    addCol(template, config) {
      let row = (config || {}).row;
      let layout = template.layout || [];
      let panelName = (config || {}).panelName;
      if (row >= 0 && row < layout.length) {
        this.addPanel(template, row, -1, panelName || this.defaultPanel.name);
      }
      return template;
    },
    // test: app.__vue__.$root.$emit("dashboard:editor",{action:"delCol", data:{row:0, col: 0} })
    delCol(template, config) {
      let row = (config || {}).row;
      let col = (config || {}).col;
      let layout = template.layout || [];
      if (col < -1) col = layout[row].length - 1;
      if (
        row >= 0 &&
        row < layout.length &&
        col >= 0 &&
        col < layout[row].length
      ) {
        layout[row].splice(col, 1);
        if (!layout[row].length) {
          this.delRow(template, {row: row});
        } else {
          resizeCols(layout[row]);
        }
      }
      this.setDrawerTab("components");
      return template;
    },
    // test: app.__vue__.$root.$emit("dashboard:editor",{action:"delPanel", panelName: "0e89229d-1980-4277-939d-bb7899bc6e65" })
    delPanel(template, config) {
      let panelName = (config || {}).panelName;
      let replaceBy = (config || {}).replaceBy;
      let ic = -1;
      let ir = -1;
      let ip = -1;
      let layout = template.layout || [];
      layout.forEach((row, rx) => {
        row.forEach((col, cx) => {
          if (
            ip == -1 &&
            (ip = col.panels.findIndex((name) => name == panelName)) >= 0
          ) {
            ir = rx;
            ic = cx;
          }
        });
      });
      var pos = template.panels.findIndex(({name}) => name == panelName);
      if (pos > -1) {
        template.panels.splice(pos, 1);
        if (ir > -1 && ic > -1 && ip > -1) {
          if (replaceBy) {
            let src = PANEL({template: replaceBy});
            if (src) {
              let panelTemplate = JSON.parse(JSON.stringify(src.template));
              if (!src.singleton) {
                panelTemplate.name = this.$utils.uuid();
              }
              return this.setPanelAt(template, {
                row: ir,
                col: ic,
                pos: ip,
                panel: panelTemplate
              });
            }
          }
          // if not replacing, remove also the panel slot at template
          layout[ir][ic].panels.splice(ip, 1);
          if (!layout[ir][ic].panels.length) {
            this.delCol(template, {row: ir, col: ic});
          }
        }
      }
      if (!layout.length) {
        this.addLayout(template, {
          row: -1,
          layout: JSON.parse(
            JSON.stringify(Layouts.find((item) => item.default).template)
          )
        });
      }
      this.cleaupLinkedPanels(template);
      return template;
    },
    setColWidth(template, config) {
      let layout = template.layout || [];
      let row = (config || {}).row;
      let col = (config || {}).col;
      let width = (config || {}).width;
      if (row >= 0 && row < layout.length) {
        width = width < 8.34 ? 9.34 : width > 95 ? 95 : width;
        if (col >= 0 && col < layout[row].length) {
          let o = parseInt(12 * (layout[row][col].width / 100));
          let n = parseInt(12 * (width / 100));
          if (o == n) return; // nothing has changed
          let diff = layout[row][col].width - width;
          layout[row][col].width = width;
          if (col < layout[row].length - 1) {
            layout[row][col + 1].width += diff;
          } else if (col > 1) {
            layout[row][col - 1].width += diff;
          }
        }
      }
      return template;
    },
    // test: app.__vue__.$root.$emit("dashboard:editor",{action:"editPanel", data:{panelName: "0e89229d-1980-4277-939d-bb7899bc6e65"} })
    editPanel(template, config) {
      // console.log("editPanel");
      let panelName = (config || {}).panelName || "";
      let panel = panelName
        ? (template?.panels || []).find(({name}) => name == panelName)
        : null;
      if (panel) {
        if (panelName != this.currentDraftPanelName) {
          this.$store.dispatch("synoptic/clearSelectedControls");
          this.$store.dispatch("dashboard/resetCurrentDraftPanel");
          this.$store.dispatch("dashboard/saveDraftPanel", {
            screenId: this.screenId,
            panelName: panelName,
            panel: panel,
            setAsCurrent: true
          });
          let path = `/dashboard/screen/edit/${this.screenId}/${panel.name}`;
          this.goto(path);
        }
        if (config.scrollTo) {
          this.scrollToPanel(panelName);
        }
        this.$root.$emit("controlSidebar:setContent", MainForm);
        // it does not allow properties edition if external
        if (panelName in (template?.linkedPanels || {})) {
          // this.$root.$emit("drawer:event", {
          //   action: "tab:activate",
          //   details: "component"
          // });
          // return;
          // let pos = template.panels.findIndex(({ name }) => name == panelName);
          // if (pos >= 0) {
          //   template.panels[pos] = panelMerge(
          //     structuredClone(panel),
          //     panel,
          //     template?.linkedPanels[panelName]
          //   );
          // }
        }
        let panelEditor = panelPropertyForm[panel.template];
        if (panelEditor) {
          if (config.showContentProperties) {
            this.$nextTick(() => {
              this.$root.$emit("controlSidebar:setContent", panelEditor.form, {
                show: true
              });
            });
            return;
          }
          if (panelEditor.widget) {
            this.$nextTick(() => {
              this.$root.$emit(
                "controlSidebar:setContentPanelWidget",
                panelEditor.widget
              );
              this.$root.$emit("drawer:event", {
                action: "tab:activate",
                details: "component"
              });
            });
            return;
          }
        }
        this.$root.$emit("controlSidebar:setContentPanelWidget", null);
      }
    },
    async scrollToPanel(panelName) {
      var el = document.getElementsByClassName(`__${panelName}__`);
      el = (el.length && el[0].closest(".row")) || null;
      if (el) el.scrollIntoView({block: "start", behavior: "smooth"});
    },
    // test: app.__vue__.$root.$emit("dashboard:editor",{action:"swapPanels", data:{a: "2s332132", b:"0e89229d"} })
    swapPanels(template, config) {
      let a = (config || {}).a || "";
      let b = (config || {}).b || "";
      let layout = template.layout || [];
      if (!(a && b && a != b && layout.length)) return;
      let pa = template.panels.find(({name}) => name == a);
      let pb = template.panels.find(({name}) => name == b);
      let sa = JSON.parse(JSON.stringify(pa.style));
      let sb = JSON.parse(JSON.stringify(pb.style));
      const _Stylist = (panel, style) => {
        if (
          panel.style["min-height"] != "auto" &&
          style["min-height"] == "auto"
        ) {
          return;
        }
        ["min-height", "max-height"].forEach((p) => {
          panel.style[p] = style[p];
        });
      };
      for (var ir in layout || []) {
        for (var ic in layout[ir] || []) {
          for (var ip in layout[ir][ic]?.panels) {
            if (layout[ir][ic]?.panels[ip] == a) {
              layout[ir][ic].panels[ip] = b;
              _Stylist(pb, sa);
            } else if (layout[ir][ic]?.panels[ip] == b) {
              layout[ir][ic].panels[ip] = a;
              _Stylist(pa, sb);
            }
          }
        }
      }
      return template;
    },
    // test: app.__vue__.$root.$emit("dashboard:editor",{action:"enablePageBrakeAt", data:{row: 3} })
    enablePageBrakeAt(template, config) {
      // console.log(config);
      let layout = template.layout || [];
      let ir = (config || {}).row;
      if (ir < 0) ir = layout.length - 1;
      if (ir >= 0 && ir < layout.length) {
        template.layoutConfig = template.layoutConfig || BaseLayoutConfig();
        let ix = template.layoutConfig.pageBreak.indexOf(ir);
        if (ix >= 0) {
          template.layoutConfig.pageBreak.splice(ix, 1);
        } else {
          template.layoutConfig.pageBreak.push(ir);
          template.layoutConfig.pageBreak.sort();
        }
      }
      return template;
    },
    resetLayoutConfig(template) {
      template.layoutConfig = template.layoutConfig || {
        rowHeight: [],
        pageBreak: [0]
      };
      template.layoutConfig.pageBreak = [0];
    },
    onEditorEvent(e) {
      if (
        e?.action &&
        e.action in this &&
        typeof this[e.action] == "function"
      ) {
        let template;
        switch (e.action) {
          case "updateWidth":
          case "newDashboard":
          case "editDashboard":
          case "openDashboard":
          case "previewDashboard":
          case "cleanUp":
          case "setDrawerTab":
            this[e.action](e.data);
            break;
          default:
            if (!this.draft) return;
            template = this[e.action](
              JSON.parse(JSON.stringify(this.draft.template)),
              e.data
            );
            // validate changes
            if (template && !isEqual(template, this.draft.template)) {
              this.updateWidth();
              this.$store.commit("dashboard/SAVE_DRAFT", {
                screenId: this.screen.id,
                template: template
              });
            }
        }
      }
    },
    updateWidth() {
      if (!this.$el.parentElement) return;
      let w = 100;
      let width = `${w}%`;
      if (!this.sidebarCollapsed) {
        if (document.getElementsByClassName("control-sidebar-bg").length) {
          let $drawer = document.getElementsByClassName(
            "control-sidebar-bg"
          )[0];
          w = this.$el.parentElement.clientWidth - $drawer.clientWidth;
          width = `${w}px`;
        }
      }
      this.width = width;
      if (this.notifyWindowResize) {
        this.notifyWindowResize();
      }
    },
    sizeBarResizeMonitor() {
      if (this.sidebarMonitor) return;
      this.$nextTick(() => {
        if (document.getElementsByClassName("control-sidebar-bg").length) {
          this.sidebarMonitor = true;
          let $drawer = document.getElementsByClassName(
            "control-sidebar-bg"
          )[0];
          this.$utils.elSizeObserver($drawer, (e) => {
            this.updateWidth();
          });
        }
      });
    },
    cleanUp() {
      // TODO: should be a single action of dashboard
      // in order to avoid changes on dashboard.js while working at FeaturedEditor branch, such sanitization has been postpond
      this.$store.commit("synoptic/RESET_PANEL");
      this.$store.dispatch("history/reset");
      this.$store.dispatch("dashboard/resetDraft");
      this.$store.dispatch("dashboard/resetCurrentDraftPanel");
      // this.$store.dispatch("dashboard/initDraft", this.screenId);
    },
    goto(path) {
      if (path) {
        this.$forceUpdate();
        if (this.$route.path == path) {
          if (this.currentDraftPanelName) {
            this.setDrawerTab("components");
          }
        } else {
          if (this.$route.path == "/dashboard/screen/edit/new") {
            this.$router.replace(path);
          } else {
            this.$router.push(path);
          }
        }
      }
    },
    initPropertyForm() {
      if (this.currentDraftPanelName) {
        this.onEditorEvent({
          action: "editPanel",
          data: {panelName: this.currentDraftPanelName}
        });
      } else {
        this.onEditorEvent({action: "editDashboard"});
      }
    },
    fetchConnectorDataList() {
      return this.$store.dispatch("dashboard/fetchResourcesFrom", {
        resource: "data",
        connectorId: this.connectorId,
        forceUpdate: false,
        once: true
      });
    }
  },
  beforeCreate() {
    this.sidebarMonitor = false;
  },
  created() {
    if (!(this.$store.getters["synoptic/imagesLibraries"] || []).length) {
      this.$store.dispatch("synoptic/fetchImagesLibraries");
      this.$store.dispatch("synoptic/fetchImages");
    }

    this.$rt.disable();
    this.$store.dispatch("dashboard/resetEditor");
    if (!(this.$store.getters["equipmentList"] || []).length) {
      this.$store.dispatch("fetchEquipmentList");
    }
    // this.$store.dispatch("dashboard/initDraft", this.screenId);
    this.$store.commit("dashboard/SET_MODE", "editor");
    this.resetDisplay();
    this.$root.$on("panelFocus", this.panelFocus);
    this.$root.$on("dashboard:editor", this.onEditorEvent);

    this.notifyWindowResize = debounce(() => {
      this.$root.$emit("panel:resized");
    }, 500);
    if (!this.screen) {
      this.fetchScreens().then(() => {
        if (this.screenId == "new") {
          // this.newDashboard();
          this.$root.$emit("dashboard:editor", {action: "newDashboard"});
        } else {
          this.$store.dispatch("dashboard/initDraft", this.screenId);
        }
      });
      return;
    } else if (!(this.screen?.template || null)) {
      this.$store.dispatch("dashboard/initDraft", this.screenId);
    } else {
      //this.template = this.screen.template;
      this.ready = true;
      this.$nextTick(() => {
        this.initPropertyForm();
      });
    }
  },
  beforeDestroy() {
    this.$rt.enable();
    this.$store.commit("synoptic/RESET_PANEL");
    this.$store.dispatch("history/reset");
    this.$root.$emit("controlSidebar:setContent", null);

    this.$root.$off("panelFocus", this.panelFocus);
    this.$root.$off("dashboard:editor", this.onEditorEvent);
  },
  mounted() {}
};
</script>
<style scoped>
.screen-panel-editor {
  position: relative;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  overflow-x: auto;
}

.screen-panel-editor::-webkit-scrollbar {
  /* width of the entire scrollbar */
  /* width: 8px; 
  height: 8px; */
  width: 0;
  height: 0;
}

.screen-panel-editor::-webkit-scrollbar-track {
  background: rgb(238, 238, 238); /* color of the tracking area */
}

.screen-panel-editor::-webkit-scrollbar-thumb {
  background-color: #9aaeb9; /* color of the scroll thumb */
  border-radius: 5px; /* roundness of the scroll thumb */
  border: 1px solid rgb(197, 173, 128); /* creates padding around scroll thumb */
}

.fa-redo {
  transform: rotateY(180deg);
}

/* .screen-panel-editor::after {
  content: "";
  background-image: url(/static/common/images/bootstrap-grid.svg);
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.1;
  top: 0;
  left: -30px;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: 1;
  pointer-events: none;
} */
</style>
